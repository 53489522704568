@import 'libs/assets/theme/mixins.scss';

.strength-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  padding: 5px; //shadow

  &.compact {
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding-left: 5px;
    > * {
      flex: 1 0 0;
      overflow: hidden;
    }
  }
}

.swiperContainer {
  margin: -5px 0;

  .swiperSlide {
    height: auto;
    width: auto;

    > * {
      height: 100%;
    }
  }

  .swiperElement {
    /* padding prevents a shadow of being cut off */
    padding: 5px;
  }
}
